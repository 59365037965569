import React from 'react';

import './Dropdown.scss';

function Dropdown(props) {
  const values = Object.keys(props.options);

  function handleChange(evt) {
    props.onChange(evt);
  }

  return (
    <select className="dropdown" {...props} value={props.defaultValue} onChange={handleChange}>
      {values.map((value) => {
        return (
          <option
            value={value}
            key={value}
          >{props.options[value]}</option>
        )
      })}
    </select>
  )
}

export default Dropdown;
