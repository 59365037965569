import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavBackstage.scss';

function NavBackstage(props) {
		return (
			<div className="navigation-backstage">
      <NavLink to="/">Go to Song List</NavLink>
			</div>
		)
}

export default NavBackstage;
