const config = {
  development: {
		api: {
			baseUrl: ''
		}
  },

  test: {
		api: {
			baseUrl: 'http://localhost:8080'
		}
  },

  production: {
		api: {
			baseUrl: 'https://singalong.buchmanns.de/api'
		}
  }
};

const env = process.env.NODE_ENV || 'production';

module.exports = config[env];
