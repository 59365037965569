import React from 'react';
import './Input.scss';

function Input(props) {

  function handleChange(evt) {
    props.onChange(evt);
  }

  return (
    <div className="input">
      <input {...props} onChange={handleChange} ></input>
    </div>
  )
}

export default Input;
