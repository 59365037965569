import React from 'react'

function MainLayout({ children }) {
  return (
    <div className="hideoverflow">
      <div className="outer">
        <div className="inner">
          { children }
        </div>
      </div>
    </div>
  )
}

export default MainLayout;
