import React from 'react';
import './InputGroup.scss'

function InputGroup(props) {
  let cssClasses = ['input-group'];
  if(props.hidelabel) { cssClasses.push('hide-label'); }

  return(
    <div className={cssClasses.join(' ')}>
      <label htmlFor={props.name}>{props.label}</label>
      {
        React.Children.map(props.children, (child) => {
          return React.cloneElement(child, {
            name: props.name,
          });
        })
      }
    </div>
  )
}

export default InputGroup;
