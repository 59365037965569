import React from 'react';
import { Redirect } from 'react-router';
import './Song.css';
import MetaData from './components/MetaData';
import ParserErrors from './components/ParserErrors';
import Chord from './components/Chord';
import conf from './conf';

class Song extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.state = {
			song: null
		}
	}

	componentDidMount() {
		fetch(`${conf.api.baseUrl}/songs/${this.props.match.params.id}`)
			.then(result => {
				if(result.status !== 200) {
					console.log('Error:', result);
					this.setState({httpStatus: result.status});
					return;
				}
				return result.json();
			})
			.then(data => {
				this.setState({song: data});
			});
	}

	formatContent(content) {
		if(content.type === 'chord') {
			return <Chord value={content.value} />
		} else {
			return this.encodeSpace(content.value);
		}
	}


	/* ************
	 * Turn trailing spaces into non-breaking spaces as they
	 * otherwise would be removed during rendering
	 * ************/
	encodeSpace(value) {
		return value ? value.replace(/ $/, '\u00A0') : '';
	}

	/* ************
	 * If a column only contains a chord but no word, this function
	 * inserts an empty word to cause a div to be rendered. This way
	 * the chord will be placed above the text line and not on the same height
	 * ************/
	addEmptyWordToOrphanedChord(column) {
		const isOrphanedChord =
			(column.length < 2 && column[0].type) === 'chord' ? true : false;

		if(isOrphanedChord) {
			column.push({type: 'text', value: ''});
			column[0].value += '  ';
		}
		return column
	}

	nextStep(item) {
		if(item.content) {
			return this.buildSong(item.content);
		} else if(Array.isArray(item)) {
			item = this.addEmptyWordToOrphanedChord(item);
			return this.buildSong(item);
		} else {
			return this.formatContent(item);
		}
	}

	buildHtml(item) {
		const cssClass = item.type ? item.type : 'column';
		return <div className={cssClass}>{this.nextStep(item)}</div>
	}

	buildHeader(metaData) {
		return (
			<React.Fragment>
			<h1>{this.state.song.metaData.title}</h1>
      <ParserErrors errors={this.state.song.errors}/>
			<div className="artist">{this.state.song.metaData.artist}</div>
			<MetaData data={this.state.song.metaData} />
		</React.Fragment>
		)
	}

	buildSong(song) {
		let html = [];
		if(!song) {return null};
		song.forEach((section) => {
			html.push(this.buildHtml(section));
		});
		return html;
	}

	render() {
		return (
			<React.Fragment>
			{this.state.httpStatus === 404 &&
				<Redirect to='/error/404' /> }
			{this.state.httpStatus !== 400 && this.state.song && (
					this.buildHeader(this.state.song.metaData)
			)}
			{this.state.httpStatus !== 400 && this.state.song && (
					this.buildSong(this.state.song.song)
			)}
			</React.Fragment>
		)
	}
}

export default Song;
