import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavContainer from './compositions/NavContainer';
import NavPublic from './compositions/NavPublic';
import NavBackstage from './compositions/NavBackstage';
import Song from './Song';
import SongList from './SongList';
import SongListBackstage from './SongListBackstage'
import Signin from './compositions/Signin';
import Editor from './editor/Editor';
import Error404 from './error/404';

import MainLayout from './layouts/MainLayout';
import AdminLayout from './layouts/AdminLayout';
import BackstageLayout from './layouts/BackstageLayout';

function App() {
  return (
    <React.Fragment>
      <Router>

        <Switch>

          <Route path="/backstage">
            <NavContainer>
              <NavBackstage />
            </NavContainer>
            <BackstageLayout>
              <Route path="/backstage/songs/new" component={Editor} />
              <Route path="/backstage/songs/:id/edit" component={Editor} />
              <Route exact path="/backstage/songs" component={SongListBackstage} />
            </BackstageLayout>
          </Route>

          <Route path="/admin">
            <AdminLayout>
              <Route path="/admin/editor" component={Editor} />
            </AdminLayout>
          </Route>

          <Route path="/">
            <NavContainer>
              <NavPublic />
            </NavContainer>
            <MainLayout>
              <Switch>
                <Route path="/login" component={Signin} />
                <Route exact path="/" component={SongList} />
                <Route path="/songs/:id" component={Song} />
                <Route component={Error404} />
              </Switch>
            </MainLayout>
          </Route>

        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
