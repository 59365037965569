import React from 'react';

function Chord(props) {
	const components = [...props.value]

	const formatedChord = components.map(char => {
		if(char === '#')
			{ return <sup>{char}</sup>; }
		else if(char === 'b')
			{ return <sup>&#9837;</sup>; }
		else if(char.match(/\d/))
			{ return <sup>{char}</sup>; }
		else if(char === ' ')
			{ return <>&nbsp;</>; }
		else
			{ return <>{char}</>; }
	});

	return <>{formatedChord}</>
}

export default Chord;
