import React from 'react';
import './BackstageLayout.scss';

function BackstageLayout({ children }) {
  return (
    <div className="backstage-layout">
      <div className="menu">
        When I grow up, I'll be a menu :-)
      </div>
      <div className="content">
      { children }
      </div>
    </div>
  )
}

export default BackstageLayout;
