import React from 'react';

import './NavContainer.scss';

function NavContainer(props) {
		return (
			<nav className="top-navigation">
        {props.children}
			</nav>
		)
}

export default NavContainer;
