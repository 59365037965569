import React from 'react';
import styles from './Error404.module.css';

function Error404() {

	return (
		<div className={styles.container}>
		<h1>404</h1>
		<h2>This is not where the music plays</h2>
		<p>We could not find the song you were looking for</p>
	</div>
	)
}

export default Error404;
