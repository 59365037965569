import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Input from '../components/Input';
import InputGroup from '../components/InputGroup';
import ButtonPrimary from '../components/ButtonPrimary';
import './Signin.scss'

import conf from '../conf';

function Signin() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [signinError, setSigninError] = useState(false);
  const history = useHistory();

  function handleChangeEmail(evt) {
    setEmail(evt.currentTarget.value);
  }

  function handleChangePassword(evt) {
    setPassword(evt.currentTarget.value);
  }

  function saveLoginExpiration(exp) {
    localStorage.setItem('loginExpiration', exp);
  }

  function handleSubmit(evt) {
    axios.post(`${conf.api.baseUrl}/signin`, {
      email,
      password
    })
      .then((result) => {
        saveLoginExpiration(result.data.loginExpiration);
        history.push('/backstage/songs');
      })
      .catch(() => {
        setSigninError(true);
      })
  }

  return(
    <div className="signin">
      <h1>Sign in</h1>

      <InputGroup label="Email">
        <Input type="text" name="email" onChange={handleChangeEmail}/>
      </InputGroup>

      <InputGroup label="Password">
        <Input type="password" name="password" onChange={handleChangePassword}/>
      </InputGroup>


      {signinError &&
      <div className="error" style={{maxWidth: '400px'}}>
        Oh no! That did not work. Make sure your email address and password are correct.
      </div>
      }
      <div className="button">
        <ButtonPrimary onClick={handleSubmit} label="Sign in" />
      </div>
    </div>
  )
}

export default Signin;
