import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import conf from './conf.js';

import ButtonPrimary from './components/ButtonPrimary';

import './SongListBackstage.scss';

function SongListBackstage() {
  const [songList, setSongList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    axios.get(`${conf.api.baseUrl}/songs`)
      .then((result) => {
        setSongList(result.data)
      })
      .catch((err) => {
        alert('Oooops, could not load songs');
      })
  }, []);

  function handleClickNewSong(){
    history.push('/backstage/songs/new')
  }

  return (
    <div className="song-list-backstage">
      <h1>Song List Backstage</h1>
      <ButtonPrimary
        className="new-song-button"
        label="New Song"
        onClick={handleClickNewSong}
      />
      <table className="song-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>By</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {songList.map((song) => {
            return (
              <tr key={song.id}>
                <td>{song.title}</td>
                <td>{song.by}</td>
                <td><Link to={`/backstage/songs/${song.id}/edit`}>edit</Link></td>
                <td><a href={`/songs/${song.id}`} rel="noreferrer" target="_blank">show</a></td>
              </tr>
            ) })
          }
        </tbody>
      </table>

    </div>
  )
};

export default SongListBackstage;
