import React from 'react';
import './MetaData.css';

function MetaData(props) {
	let dataCopy = Object.assign({}, props.data);
	delete dataCopy.title;
	delete dataCopy.artist;
	delete dataCopy.language;

  Object.keys(dataCopy).forEach((k) => {
    if(dataCopy[k] === null) { delete dataCopy[k]; }
  })

	function buildList(data) {
		const keys = Object.keys(data);
		return keys.map((key) => {
			const value = data[key];
			return <li key={key}>{uppcaseFirstLetter(key)} <span>{value}</span></li>
		});
	}

	function uppcaseFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.substring(1);
	}

	return (
		<ul className="song-meta-data">
			{buildList(dataCopy)}
		</ul>
	)
}

export default MetaData;
