import React from 'react';
import './ParserErrors.css';

function ParserErrors(props) {

  function buildList(errors) {
    return (
      <ul>
        {errors.map((error) => {
          return (
            <li>
              <h2>{error.name}</h2>
              {error.message}
            </li>
          )
        })}
      </ul>
    )
  }


  if(!props.errors) {
    return null;
  }

  return (
    <section class="parser-errors">
      <h1>Parser Errors</h1>
      {buildList(props.errors)}
    </section>
  )
}

export default ParserErrors;
