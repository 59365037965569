function useCurrentUser() {
  const storedExp = new Date(localStorage.getItem('loginExpiration'));
  const now = new Date();

  function isAuthenticated() {
    return storedExp > now;
  }

  return {
    isAuthenticated: isAuthenticated(),
    isNotAuthenticated: !isAuthenticated()

  }
}

export default useCurrentUser;
