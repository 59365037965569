import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import conf from '../conf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ButtonPrimary from '../components/ButtonPrimary';
import InputGroup from '../components/InputGroup';
import Input from '../components/Input';
import Dropdown from '../components/Dropdown';

import './Editor.scss';

const{ removeEmptyLines, toChordPro } = require('./utils');

function EditorMain() {
  const [song, setSong] = useState('');
  const [title, setTitle] = useState();
  const [artist, setArtist] = useState();
  const [composer, setComposer] = useState();
  const [lyrics, setLyrics] = useState();
  const [year, setYear] = useState();
  const [capo, setCapo] = useState();
  const [language, setLanguage] = useState();

  const { id } = useParams();
  const history = useHistory();

  const languageOptions = {
    '': '',
    en: 'English',
    de: 'German',
    sv: 'Swedish',
    ar: 'Arabic',
  };

  useEffect(() => {
    if(!id) { return }
    axios.get(`${conf.api.baseUrl}/songs/${id}?songFormat=txt`)
      .then((result) => {
        setSong(result.data.song);
        setTitle(result.data.metaData.title);
        setArtist(result.data.metaData.artist);
        setComposer(result.data.metaData.composer);
        setLyrics(result.data.metaData.lyrics);
        setYear(result.data.metaData.year);
        setCapo(result.data.metaData.capo);
        setLanguage(result.data.metaData.language);
      })
      .catch((err) => {
        alert(`Oooops, could not load song ${id}`);
      })
  }, [id]);

  function handleChange(evt) {
    const property = evt.currentTarget.name;
    const value = evt.currentTarget.value

    if(property === 'song') { setSong(value); }
    if(property === 'title') { setTitle(value); }
    if(property === 'artist') { setArtist(value); }
    if(property === 'composer') { setComposer(value); }
    if(property === 'lyrics') { setLyrics(value); }
    if(property === 'year') { setYear(value); }
    if(property === 'capo') { setCapo(value); }
    if(property === 'language') { setLanguage(value); }
  }

  function handleEmptyLinesClick() {
    setSong(removeEmptyLines(song));
  }

  function handleToChordProClick() {
    setSong(toChordPro(song));
  }

  function handleSubmit() {
    const data = { songTxt: song, title, artist, composer, lyrics, year, capo, language };
    const method = id ? 'put' : 'post';
    let url = `${conf.api.baseUrl}/songs`;
    if(id) { url = url + `/${id}` }

    axios({ method, url, data })
      .then((response) => {
        const songId = response.data.id || id;
        toast.success(method === 'post' ? 'Song created' : 'Song saved');
        history.push(`/backstage/songs/${songId}/edit`)
      })
      .catch(() => {
        const action = method === 'post' ? 'create' : 'save';
        toast.error(`Could not ${action} song :-(`);
      })
  }

  function handleCancel() {
    history.push('/backstage/songs');
  }

  return (
    <div className="editor">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />


      <h1>{ id ? 'Edit' : 'Create' } Song</h1>
      <ButtonPrimary
        className="button-top save"
        label={id ? 'Save' : 'Create'}
        onClick={handleSubmit}
      />
      <ButtonPrimary
        className="button-top cancel"
        label="Close"
        onClick={handleCancel}
      />


      <div className="meta-data">
        <div>
          <InputGroup
            name="title"
            label="Title" >
            <Input
              defaultValue={title}
              onChange={handleChange} >
            </Input>
          </InputGroup>

          <InputGroup
            name="artist"
            label="Artist" >
            <Input
              defaultValue={artist}
              onChange={handleChange} >
            </Input>
          </InputGroup>


          <InputGroup
            name="composer"
            label="Composer" >
            <Input
              defaultValue={composer}
              onChange={handleChange} >
            </Input>
          </InputGroup>

          <InputGroup
            label="Song Language"
            name="language" >
            <Dropdown
              options={languageOptions}
              onChange={handleChange}
              defaultValue={language}
            />
          </InputGroup>

        </div>

        <div>
          <InputGroup
            name="lyrics"
            label="Lyrics" >
            <Input
              defaultValue={lyrics}
              onChange={handleChange} >
            </Input>
          </InputGroup>

          <InputGroup
            name="capo"
            label="Capo" >
            <Input
              defaultValue={capo}
              onChange={handleChange} >
            </Input>
          </InputGroup>

          <InputGroup
            name="year"
            label="Year" >
            <Input
              defaultValue={year}
              onChange={handleChange} >
            </Input>
          </InputGroup>

        </div>
      </div>

      <ButtonPrimary
        label="Remove empty lines"
        onClick={handleEmptyLinesClick}
        className="remove-lines"
      />
      <ButtonPrimary
        label="To ChordPro"
        onClick={handleToChordProClick}
      />
      <br /> <br />
      <textarea
        value={song}
        name="song"
        onChange={handleChange}
      >
      </textarea>
    </div>
  )
}


export default EditorMain;
