import React from 'react';
import './ButtonPrimary.css';

function ButtonPrimary(props) {
  let classes = ['button-primary']
  classes.push(props.className)

  return (
    <button
      className={classes.join(' ')}
      onClick={props.onClick}
    >{props.label}</button>
  )
}

export default ButtonPrimary;
