import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useCurrentUser from '../hooks/useCurrentUser';

import './NavPublic.scss';

function NavPublic(props) {
  const location = useLocation();
  const currentUser = useCurrentUser();
  const [showQrCode, setShowQrCode] = useState(false);
  const [icon, setIcon] = useState('share-alt-solid');

  function toggleShare() {
    setShowQrCode(!showQrCode);
    setIcon(showQrCode ? 'share-alt-solid' : 'times-solid');
  }


  return (
    <div className="navigation-public">
      {location.pathname !== '/' &&
      <NavLink to="/">
        <img className="icon" src="/img/chevron-left-solid.svg"
          alt="arrow to the left" />Song List
      </NavLink>
      }

    {location.pathname === '/' && currentUser.isNotAuthenticated &&
      <NavLink to="/login">Log in</NavLink>
      }

    {location.pathname === '/' && currentUser.isAuthenticated &&
      <NavLink to="/backstage/songs">Go Backstage</NavLink>
      }

      <img
        className="share"
        alt="Share link to singalong"
        src={`/img/${icon}.svg`}
        onClick={toggleShare}
      />

      {showQrCode &&
      <div className="sharing">
        <img alt="QR-code with linke to singalong" src="/img/share-qr.png"/>
      </div>
      }
    </div>
  )
}

export default NavPublic;
