import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SongList.css';
import conf from './conf.js';
import Input from './components/Input';
import InputGroup from './components/InputGroup';
//import Spinner from './components/Spinner.js';

function SongList() {
  const [initialSongList, setInitialSongList] = useState(null);
  const [songList, setSongList] = useState(null);

  useEffect(() => {
    fetch(`${conf.api.baseUrl}/songs`)
      .then(result => { return result.json()})
      .then(data => {
        setSongList(data);
        setInitialSongList(data);
      })
  }, []);

  useEffect(() => {
    restoreScrollPosition();
  });

  function isLastInBlock(song, index) {
    const nextSong = songList[index + 1] || {};
    const nextTitle = nextSong.title || ' ';
    if(song.title[0].toLowerCase() !== nextTitle[0].toLowerCase()) {
      return true;
    } else {
      return false
    }
  }

  function saveScrollPosition() {
    sessionStorage.setItem('songListScrollPosition', window.pageYOffset);
  }

  function restoreScrollPosition() {
    window.scrollTo(0, sessionStorage.getItem('songListScrollPosition'));
  }

  function createList() {
    return songList.map((song, index) => {
      return (
        <li key={song.id} className={isLastInBlock(song, index) ? 'last-in-block' : null}>
          <Link to={`/songs/${song.id}`} onClick={saveScrollPosition}>{song.title}</Link> <span className="by"> {song.by && '|'} {song.by}</span>
        </li>
      );
    })
  }

  function handleSearchChange(evt) {
    const searchTerm = evt.currentTarget.value
    window.scrollTo(0, 0);
    saveScrollPosition();
    const lowerCaseTerm = searchTerm.toLowerCase();
    const filteredList = initialSongList.filter((song) => {
      const artist = song.by ? song.by : '';
      const matchesArtist = artist.toLowerCase().includes(lowerCaseTerm);
      const matchesTitle = song.title.toLowerCase().includes(lowerCaseTerm);
      return matchesArtist || matchesTitle
    })

    setSongList(filteredList);
  }

  function renderContent() {
    return (
      <>
        <InputGroup
          name="search"
          label="Search song or artist"
          hidelabel="true"
        >
          <Input
            onChange={handleSearchChange}
            placeholder="Search song or artist"
          />
        </InputGroup>

        <h1>Song List</h1>
        <ul>
          {createList()}
        </ul>
      </>
    )
  }

  return (
    <React.Fragment>
      {/* !this.state.songList && <Spinner /> */}
      {songList && renderContent()}
    </React.Fragment>
  )
};

export default SongList;
