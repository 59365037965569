
/**
 * @param song String one line of a song
 * @returns Array<Object> of the chords detected and their position on the line
 */
function findChords(line) {
  const notes = "[CDEFGAB]";
  const accidentals = "(b|bb)?";
  const chords = "(m|maj7|maj|min7|min|sus|dim|aug)?";
  const suspends = "(1|2|3|4|5|6|7|8|9)?";
  const sharp = "(#)?";
  const regex = new RegExp("\\b" + notes + accidentals + sharp + chords + suspends + "\\b" + sharp, "g");

  let match;
  let matchedChords = [];

  while ((match = regex.exec(line)) != null) {
    matchedChords.push({value: match[0], position: match.index});
  }

  return matchedChords
}

/**
 * @param song String a textual represention of a whole song
 * @returns String textual representation of a song without excess empty lines
 */
function removeEmptyLines(song) {
  return song.replace(/$^\n/gm, '');
}

/**
 * @param song String a textual represention of a whole song
 * @returns Array<Object> song marked up with type of each line
 */
function detectLineTypes(song) {
  const lines = song.split('\n');

  return lines.map((line) => {
    const chords = findChords(line);
    let type;
    let value;

    if(chords.length <= 0) {
      type = 'text';
      value = line;
    } else {
      const chordLength = chords.reduce((accumulator, chord) => {
        return accumulator + chord.value.length;
      }, 0);
      const lineLength = line.replace(/ /g, '').length - chordLength;

      type = chordLength > lineLength ? 'chords' : 'text';
      value = chordLength > lineLength ? chords : line;
    }

    return {type, value}
  })
}

/**
 * @param song String
 */
function toChordPro(song) {
  const typedLines = detectLineTypes(song);
  let formated = [];

  typedLines.forEach((line, i) => {
    const previousLine = typedLines[i-1];
    const previousType = previousLine ? previousLine.type : undefined;

    if(line.type === 'text' && previousType === 'chords') {
      formated.push(mergeChordsIntoText(previousLine.value, line.value))
    } else if (line.type === 'text') {
      formated.push(line.value);
    }
  });

  return formated.join('\n');
}

function mergeChordsIntoText(chordsLine, textLine) {
  const chords = [...chordsLine];
  let lineEnd = textLine.length;
  let mergedLine = [];

  while(chords.length > 0) {
    const chord = chords.pop();
    const textSection = textLine.substring(chord.position, lineEnd)
    mergedLine.unshift('[', chord.value, ']', textSection);
    lineEnd = chord.position;
  }

  mergedLine.unshift(textLine.substring(0, lineEnd))

  return mergedLine.join('');
}


export {
  toChordPro,
  removeEmptyLines
};
