import React from 'react';
import './AdminLayout.css';

function AdminLayout({ children }) {
  return (
    <div className="admin-layout">
      <h1>Admin</h1>
          { children }
    </div>
  )
}

export default AdminLayout;
